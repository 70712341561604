import React from "react";
import { Helmet } from "react-helmet";
import { Img } from "../../components";

export default function ThumbnailPage() {
  return (
    <>
      <Helmet>
        <title>GPT</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full justify-center bg-indigo-100 px-14 py-[98px] md:p-5">
        <Img
          src="images/img_chat_ai_1.png"
          alt="chataione_one"
          className="mx-auto h-[883px] w-full max-w-[1570px] rounded-[34px] object-cover md:h-auto"
        />
      </div>
    </>
  );
}
